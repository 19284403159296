<template>
  <div id="Index">
    <div class="titleTop" v-if="false">
      <img src="../assets/img/龙港.jpg" />
      <h1 class="title">印刷行业产业大脑</h1>
      <p class="weather">
        <span>{{ slweather }}</span
        >&nbsp;&nbsp;<span>{{ temperature }}</span
        >|<span>{{ sldate }}</span>
      </p>
    </div>
    <!-- <i class="el-icon-menu" @click="isshowmenu"></i> -->
    <!-- <img src="../assets/img/change.png" alt="" @click="isshowmenu" class="imgs"> -->
    <div class="bottom">
      <div class="selectleft" v-if="showmenu">
        <router-link
          :class="['selectitem', name == 'industrialprofile' ? 'active' : '']"
          to="/industrialprofile"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">产业概况</p>
          <img
            src="../assets/img/change.png"
            alt=""
            @click="isshowmenu"
            class="imgs"
          />
        </router-link>

        <router-link
          :class="['selectitem', name == 'chain' ? 'active' : '']"
          to="/chain"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">产业链图谱</p>
        </router-link>
        <router-link
          :class="['selectitem', name == 'Amap' ? 'active' : '']"
          to="/Amap"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">产业地图</p>
        </router-link>
        <router-link
          :class="['selectitem', name == 'operationalanalysis' ? 'active' : '']"
          to="/operationalanalysis"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">运行分析</p>
        </router-link>
        <router-link
          :class="['selectitem', name == 'supervise' ? 'active' : '']"
          to="/supervise"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">信用+监测</p>
        </router-link>
        <router-link
          :class="['selectitem', name == 'cultivate' ? 'active' : '']"
          to="/cultivate"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">企业培育</p>
        </router-link>
        <router-link class="selectitem" to="/policy">
          <!-- <router-link :class="['selectitem',name=='policy'?'active':'']" to="/policy">-->
          <p class="lefticon"></p>
          <p class="lefttitle">产业政策</p>
        </router-link>
        <router-link
          class="selectitem"
          style="pointer-events: none; color: #6b6b6b"
          to="/"
        >
          <!-- <router-link :class="['selectitem',name=='talents'?'active':'']" to="/talents">-->
          <p class="lefticon"></p>
          <p class="lefttitle">产业人才</p>
        </router-link>
        <router-link
          :class="['selectitem', name == 'tspark' ? 'active' : '']"
          to="/tspark"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">特色园区</p>
        </router-link>
        <router-link
          class="selectitem"
          style="pointer-events: none; color: #6b6b6b"
          to="/"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">生产安全</p>
        </router-link>
        <router-link
          class="selectitem"
          style="pointer-events: none; color: #6b6b6b"
          to="/"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">物流仓储</p>
        </router-link>
      </div>
      <div class="selectleft" v-if="!showmenu">
        <router-link
          :class="['selectitem', name == 'subcompany' ? 'active' : '']"
          to="/subcompany"
          class="active"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">企业减负</p>
          <img
            src="../assets/img/change.png"
            alt=""
            @click="isshowmenu"
            class="imgs"
          />
        </router-link>
        <router-link
          :class="['selectitem', name == 'standardquality']"
          to="/standardquality"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">质量标准</p>
        </router-link>

        <router-link
          :class="['selectitem', name == 'talentservice' ? 'active' : '']"
          to="/talentservice"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">人才服务</p>
        </router-link>
        <!-- <router-link class="selectitem" style="pointer-events: none;color: #6B6B6B;" to="/"> -->
        <router-link
          :class="['selectitem', name == 'cash' ? 'active' : '']"
          to="/cash"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">政策兑现</p>
        </router-link>
        <!-- <router-link class="selectitem" style="pointer-events: none;color: #6B6B6B;" to="/"> -->
        <router-link
          :class="['selectitem', name == 'collection' ? 'active' : '']"
          to="/collection"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">采集中心</p>
        </router-link>
        <!-- <router-link class="selectitem" style="pointer-events: none;color: #6B6B6B;" to="/"> -->
        <router-link
          :class="['selectitem', name == 'Design' ? 'active' : '']"
          to="/Design"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">研发设计</p>
        </router-link>
        <!-- <router-link class="selectitem" style="pointer-events: none;color: #6B6B6B;" to="/"> -->
        <router-link
          :class="['selectitem', name == 'coordination' ? 'active' : '']"
          to="/coordination"
        >
          <p class="lefticon"></p>
          <p class="lefttitle">产能协同</p>
        </router-link>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { findWeather } from "@/api/index/index";
export default {
  components: {},
  data() {
    return {
      slweather: null,
      sldate: null,
      temperature: null,
      time: null,
      name: null,
      showmenu: true,
    };
  },
  computed: {
    pageKey() {
      return store.state.pageKey;
    },
  },
  methods: {
    isshowmenu() {
      this.showmenu = !this.showmenu;
    },
  },
  mounted() {
    // console.log(navigator.userAgent.toLowerCase(),11111111111)

    this.name = this.$route.name;
    findWeather()
      .then((res) => {
        //console.log(res)
        this.slweather = res.data.now.text;
        this.temperature = res.data.now.temp + "℃";
        this.sldate = res.data.date;
      })
      .catch((err) => {
        console.log(err);
      });
    this.time = setInterval(() => {
      this.$moment.locale("zh-cn");
      this.sldate = this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss dddd");
    }, 1000);
  },
  destroyed() {
    clearInterval(this.time);
  },
  watch: {
    $route: function (to, from) {
      this.name = to.name;
    },
  },
};
</script>

<style lang="less" scoped>
#Index {
  width: 1440px;
  height: 100%;
  background-color: #1492ff;
  margin: 0 auto;
  // &::-webkit-scrollbar {
  //   display: none;
  //   width: 0;
  // }
}

.bottom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  .biean {
    text-align: center;
    color: white;
    margin: 20px;
  }
  .biean:hover {
    cursor: pointer;
    color: #00ffff;
  }
}
.titleTop {
  img {
    height: 95px;
  }
  height: 95px;
  width: 100%;
  display: flex;
  align-items: center;
  // background-image: -webkit-linear-gradient(left,#1492ff,#003e78,#003e78,#003e78);
  // background-image: -o-linear-gradient(right,#1492ff,#003e78,#003e78,#003e78);
  // background-image: -moz-linear-gradient(right,#1492ff,#003e78,#003e78,#003e78);
  // background-image: linear-gradient(to right,#1492ff,#003e78,#003e78,#003e78);
  background-color: #003e79;
  .title {
    color: #379dfc;
    font-size: 33px !important;
    margin-left: -146px !important;
  }
  .weather {
    margin-left: auto;
    color: #99cbf9;
    span {
      margin: 0 8px;
    }
  }
}
#Index::-webkit-scrollbar {
  display: none;
  width: 0 !important
}
.selectleft {
  width: 13%;
  background-color: #003e78;
  color: #ffffff;
  .selectitem {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: center;
    // border-bottom: 1px solid #f2f3f5;
    p:nth-child(2) {
      font-size: 20px;
    }
    color: #ffffff;
  }
  .selectitem:nth-child(1) {
    // border-top: 1px solid #f2f3f5;
  }
  .selectitem:hover {
    cursor: pointer;
    background-color: #59aaf5;
  }
  .active {
    background-color: #1492ff;
    // border-top: 1px solid #00e1db;
    // border-bottom: 1px solid #00e1db;
  }
}
.imgs {
  position: absolute;
  top: 18px;
  left: 150px;
  width: 25px;
  height: 25px;
}
</style>
